/* src/Dashboard.css */
.site-layout-background {
    background: #fff;
  }
  
  .logo {
    height: 64px;
    background: #001529; /* Dark blue for logo background */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .site-layout-background {
    background: #fff;
  }
  
  .ant-menu-item {
    color: #fff; /* White text for menu items */
  }
  
  .ant-menu-item-selected {
    background: #1890ff; /* Highlight color for selected menu item */
    color: #fff;
  }
  
  .ant-menu-item:hover {
    background: #1890ff; /* Hover color */
    color: #fff;
  }
  
  .ant-btn-text {
    color: #1890ff; /* Color for buttons */
  }
  
  .ant-btn-text:hover {
    color: #40a9ff; /* Hover color for buttons */
  }
  