/* src/components/pages/customer/customer.css */
.customer-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .customer-form,
  .customer-list {
    flex: 1 1 100%;
    max-width: 100%;
  }
  
  @media (min-width: 768px) {
    .customer-form,
    .customer-list {
      flex: 1 1 48%;
    }
  }
  
  @media (min-width: 1200px) {
    .customer-form {
      flex: 1 1 40%;
    }
    .customer-list {
      flex: 1 1 55%;
    }
  }
  